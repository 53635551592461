import {createRouter, createWebHashHistory} from 'vue-router'
import walletNewRoutes from '@/router/walletNew'

const routes = [
  {
    path: '/',
    name: 'wallet',
    meta: {
      cnName: '',
      usName: 'Wallet'
    },
    component: () => import('@/views/walletNew/index.vue')
  },
  {
    path: '/revenue_details',
    name: 'revenue_details',
    meta: {
      cnName: '',
      usName: 'Revenue Details'
    },
    component: () => import('@/views/wallet/revenue_details.vue')
  },
  {
    path: '/down/:userId',
    name: 'down',
    meta: {
      cnName: '',
      usName: 'Download'
    },
    component: () => import('@/views/download/index.vue')
  },
  {
    path: '/friend_withdraw',
    name: 'friend_withdraw',
    meta: {
      cnName: '',
      usName: 'Friend Withdraw'
    },
    component: () => import('@/views/wallet/friend_withdraw.vue')
  },
  {
    path: '/data_report',
    name: 'data_report',
    meta: {
      cnName: '',
      usName: 'Data Report'
    },
    component: () => import('@/views/wallet/data_report.vue')
  },
  {
    path: '/bill_detail',
    name: 'bill_detail',
    meta: {
      cnName: '',
      usName: 'Bill Detail'
    },
    component: () => import('@/views/wallet/bill_detail.vue')
  },
  {
    path: '/income_detail',
    name: 'income_detail',
    meta: {
      cnName: '',
      usName: 'Income Detail'
    },
    component: () => import('@/views/wallet/income_detail.vue')
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    meta: {
      cnName: '',
      usName: 'Withdrawal'
    },
    component: () => import('@/views/wallet/withdrawal.vue')
  },
  {
    path: '/withdrawal_method',
    name: 'withdrawal_method',
    meta: {
      cnName: '',
      usName: 'WithdrawalMethod'
    },
    component: () => import('@/views/wallet/withdrawal_method.vue')
  },
  {
    path: '/withdrawal_method_bind',
    name: 'withdrawal_method_bind',
    meta: {
      cnName: '',
      usName: 'WithdrawalMethodBind'
    },
    component: () => import('@/views/wallet/withdrawal_method_bind.vue')
  },
  {
    path: '/withdrawal_record',
    name: 'withdrawal_record',
    meta: {
      cnName: '',
      usName: 'Withdrawal Record'
    },
    component: () => import('@/views/wallet/withdrawal_record.vue')
  },
  {
    path: '/balance',
    name: 'balance',
    meta: {
      cnName: '',
      usName: 'Balance'
    },
    component: () => import('@/views/wallet/balance.vue')
  },
  {
    path: '/performance',
    name: 'performance',
    meta: {
      cnName: '',
      usName: 'Performance'
    },
    component: () => import('@/views/wallet/performance.vue')
  },
  {
    path: '/anchor_record',
    name: 'anchor_record',
    meta: {
      cnName: '',
      usName: 'Anchor Record'
    },
    component: () => import('@/views/wallet/anchor_record.vue')
  },
  {
    path: '/daily_plan_bak',
    name: 'daily_plan',
    meta: {
      cnName: '',
      usName: 'Daily Plan'
    },
    component: () => import('@/views/anchor/daily_plan.vue')
  },
  {
    path: '/safe_center',
    name: 'safe_center',
    meta: {
      cnName: '',
      usName: 'Safe Center'
    },
    component: () => import('@/views/safe_center/index.vue')
  },
  {
    path: '/marketing/raffle',
    name: 'raffle',
    meta: {
      cnName: '',
      usName: 'Raffle'
    },
    component: () => import('@/views/marketing/raffle/index.vue')
  },
  ...walletNewRoutes
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.usName;
  if (title) {
    document.title = title;
  }
  next();
});

export default router
