import {createApp} from 'vue'
import App from './App.vue'
import router from '@/router'
import {store} from '@/store'
import 'lib-flexible/flexible.js'
import {Locale, Dialog} from 'vant';
// Toast
import 'vant/es/toast/style';
// Dialog
import 'vant/es/dialog/style';
// Notify
import 'vant/es/notify/style';
// ImagePreview
import 'vant/es/image-preview/style';

// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
// nutui-bingo
import {TurnTable} from "@nutui/nutui-bingo";
import "@nutui/nutui-bingo/dist/style.css";

import Vconsole from 'vconsole';


Locale.use('en-US', enUS);



const app = createApp(App)
app.config.productionTip = false
if(import.meta.env.MODE !== 'production'){
  const vConsole = new Vconsole()
  app.use(vConsole)
}
app
  .use(store)
  .use(router)
  .use(TurnTable)
  .use(Dialog)
  .mount('#app')
export default app
